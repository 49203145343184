import * as React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import { alpha, useTheme } from '@mui/material'
import useRemoteConfig from '~/lib/hooks/useRemoteConfig'
import { canFakeRedirect, updateLastLinkRedirectionTimeInStorage } from '~/lib/helpers'
import { ROUTES } from '~/lib/constants'

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({})

interface NextLinkComposedProps
    extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
        Omit<NextLinkProps, 'href' | 'as' | 'onClick' | 'onMouseEnter' | 'onTouchStart'> {
    to: NextLinkProps['href'];
    linkAs?: NextLinkProps['as'];
}

export const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(
    function NextLinkComposed(props, ref) {
        const { to, linkAs, replace, scroll, shallow, prefetch, locale, ...other } = props

        return (
            <NextLink
                href={to}
                prefetch={prefetch}
                as={linkAs}
                replace={replace}
                scroll={scroll}
                shallow={shallow}
                locale={locale}
                {...other}
            />
        )
    },
)

export type AppLinkProps = {
    activeClassName?: string;
    as?: NextLinkProps['as'];
    href: NextLinkProps['href'];
    linkAs?: NextLinkProps['as']; // Useful when the as prop is shallow by styled().
    noLinkStyle?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
    Omit<MuiLinkProps, 'href'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
export const AppLink = React.forwardRef<HTMLAnchorElement, AppLinkProps>(function Link(props, ref) {
    const {
        activeClassName = 'active',
        as,
        className: classNameProps,
        href,
        linkAs: linkAsProp,
        locale,
        noLinkStyle,
        prefetch,
        replace,
        role, // Link don't have roles.
        scroll,
        shallow,
        ...other
    } = props

    const router = useRouter()
    const pathname = typeof href === 'string' ? href : href.pathname
    const className = clsx(classNameProps, {
        [activeClassName]: router.pathname === pathname && activeClassName,
    })

    const isExternal =
        typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0)

    if (isExternal) {
        if (noLinkStyle) {
            return <Anchor className={className} href={href} ref={ref} {...other} />
        }

        return <MuiLink className={className} href={href} ref={ref} {...other} />
    }

    const linkAs = linkAsProp || as
    const nextjsProps = { to: href, linkAs, replace, scroll, shallow, prefetch, locale }

    if (noLinkStyle) {
        return <NextLinkComposed className={className} ref={ref} {...nextjsProps} {...other} />
    }

    return (
        <MuiLink
            component={NextLinkComposed}
            className={className}
            ref={ref}
            {...nextjsProps}
            {...other}
        />
    )
})

// Should update last link time storage item on any real click, and popunder

export type PopunderAppLinkProps = {
    puCat?: string // full category such as Tech & Electronics/Audio
    puRID?: string // review id, slug
    pu?: boolean // random, likely based off cookies
}

export type ThemedAppLinkProps = {
    href: NextLinkProps['href']
    color?: string
    hoverColor?: string
    secondary?: boolean
    underline?: boolean
} & Omit<AppLinkProps, 'href' | 'underline'> & PopunderAppLinkProps

export const ThemedAppLink = React.forwardRef<HTMLAnchorElement, ThemedAppLinkProps>(function Link(props, ref) {
    const {
        hoverColor,
        color,
        secondary,
        underline,
        puCat,
        puRID,
        pu,
        ...other
    } = props

    const remoteConfig = useRemoteConfig()

    const theme = useTheme()
    const isPopunderComponent = pu || puCat || puRID

    let popunderLink = `${ROUTES.outlink}/pu`
    if (puRID) {
        popunderLink = `${popunderLink}?rid=${encodeURIComponent(puRID)}`
    } else if (puCat) {
        popunderLink = `${popunderLink}?cat=${encodeURIComponent(puCat)}`
    }

    const onClick = (event) => {
        if (isPopunderComponent && remoteConfig.pu && canFakeRedirect(false, remoteConfig.ignoreFRF)) {
            event.preventDefault()
            updateLastLinkRedirectionTimeInStorage()

            window.open(props.href.toString(), '_blank')
            window.location.href = popunderLink
        } else {
            // use possible existing onclick handler instead
            props.onClick && props.onClick(event)
        }
    }

    const setSecondaryColor = props.color === undefined && !!secondary

    const themedPrimaryColor = theme.palette.primary.main
    const themedPrimaryHoverColor = alpha(theme.palette.primary.main, 0.85)
    const themedSecondaryColor = setSecondaryColor && theme.palette.secondary.main
    const themedSecondaryHoverColor = setSecondaryColor && alpha(theme.palette.secondary.main, 0.85)

    const computedColor = props.color || themedSecondaryColor || themedPrimaryColor
    const computedHoverColor = props.hoverColor || (color && alpha(color, 0.85)) || themedSecondaryHoverColor || themedPrimaryHoverColor
    const textDecoration = underline ? 'underline' : 'none'

    return (
        <AppLink
            sx={{
                textDecoration,
                color: computedColor,
                '&:link': {
                    color: computedColor,
                },
                '&:visited': {
                    color: computedColor,
                },
                '&:hover': {
                    color: computedHoverColor,
                },
                '&:active': {
                    color: computedColor,
                },
            }}
            {...other}
            onClick={onClick}
        />
    )
})
