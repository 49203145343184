import { createContext, MutableRefObject } from 'react'
import { DataType } from 'csstype'
import Box = DataType.Box
import { categoryToSlug, getCategoryMeta, getCategoryMetaMapKeys } from '~/lib/categories'
import { getReviewCategories } from '~/lib/review'
import { includes } from 'lodash'
import { NameAndSlug } from '~/types/pagedata'

export interface AppData {
    navigationProps: NavigationProps
    subscribeRef: MutableRefObject<Box>
}

// not necessary to define defaults, unless absolutely needed (otherwise ill have some undefines unnecessarily)
// @ts-ignore
export const AppDataContext = createContext<AppData>({})

export interface NavigationProps {
    categoryNavData: {
        name: string
        shortName: string
        slug: string
        expandedItems: NameAndSlug[]
    }[]

    standoutCategoryIndices: number[]
}

/**
 * INTERNAL
 */
export function buildNavigationProps(): NavigationProps {
    const reviewCategories = getReviewCategories()
    const allCategoryKeys = getCategoryMetaMapKeys()

    // keys we want to separate out from "More"
    const standoutCategoryKeys = ['tech', 'outdoors', 'kitchen', 'appliances']
    const standoutCategoryIndices = [] // found indices of the above categories

    const categoryNavData = allCategoryKeys.map((metaKey, i) => {
        const { name, shortName, slug } = getCategoryMeta(metaKey)
        const subcategories = reviewCategories[metaKey]
        const expandedItems = subcategories.map(name => ({
            name,
            slug: `${slug}/${categoryToSlug(name)}`,
        }))

        // if this is one of the standout categories, store its index for quick rendering
        if (includes(standoutCategoryKeys, metaKey)) {
            const indexOfStandoutKey = standoutCategoryKeys.findIndex(it => it == metaKey)
            standoutCategoryIndices[indexOfStandoutKey] = i
        }

        return {
            name,
            shortName,
            slug,
            expandedItems,
        }
    })

    return {
        categoryNavData,
        standoutCategoryIndices,
    }
}