import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { alpha, useTheme } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import { ThemedAppLink } from 'components/AppLink'

import NavItem from './components'
import { Stack, Typography } from '@mui/material'
import { AppDataContext } from '~/lib/contexts/AppContexts'
import { scrollToRef } from '~/lib/helpers'
import { includes } from 'lodash'
import { useRouter } from 'next/router'
import { DOMAIN } from '~/lib/constants'

interface Props {
    setIsSidebarOpen: (value) => void
    colorInvert: boolean
}

const Topbar: React.FC<Props> = ({ setIsSidebarOpen, colorInvert = false }) => {
    const theme = useTheme()
    const router = useRouter()
    const { navigationProps, subscribeRef } = useContext(AppDataContext)
    const { categoryNavData, standoutCategoryIndices } = navigationProps

    const moreCategoryNavData = categoryNavData.filter((it, i) => !includes(standoutCategoryIndices, i))
    const currentPathname = (new URL(`https://${DOMAIN}${router.asPath}`)).pathname

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
            height={96}
        >
            <ThemedAppLink href={'/'} pu width={210} aria-label={'Go to homepage'}>
                <Box
                    component={'img'}
                    src={'/images/reviewhub-logo-blue.svg'}
                    alt='ReviewHub logo'
                    height={1}
                    width={1}
                />
            </ThemedAppLink>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
                <Stack direction={'row'} spacing={4}>
                    {standoutCategoryIndices.map(i => {
                        const { name, shortName, slug, expandedItems } = categoryNavData[i]
                        return (
                            <NavItem
                                key={slug}
                                name={shortName || name}
                                id={slug}
                                currentPathname={currentPathname}
                                items={expandedItems}/>
                        )
                    })}
                    <NavItem
                        name={'More'}
                        id={'more'}
                        currentPathname={currentPathname}
                        items={moreCategoryNavData}/>
                </Stack>

                <Box marginLeft={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => scrollToRef(subscribeRef)}
                    >
                        Subscribe
                    </Button>
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
                <Button
                    onClick={() => setIsSidebarOpen(true)}
                    aria-label="Menu"
                    variant={'outlined'}
                    sx={{
                        borderRadius: 2,
                        minWidth: 'auto',
                        padding: 1,
                        borderColor: alpha(theme.palette.divider, 0.2),
                    }}
                >
                    <MenuIcon/>
                </Button>
            </Box>
        </Box>
    )
}

export default Topbar
