import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'

import NavItem from './components/NavItem'
import { ThemedAppLink } from '~/components/AppLink'
import { AppDataContext } from '~/lib/contexts/AppContexts'
import { scrollToRef } from '~/lib/helpers'
import { useRouter } from 'next/router'
import { DOMAIN } from '~/lib/constants'

interface Props {
    setIsSidebarOpen: (value) => void
}

const SidebarNav: React.FC<Props> = ({ setIsSidebarOpen }) => {
    const router = useRouter()
    const { navigationProps, subscribeRef } = useContext(AppDataContext)
    const { categoryNavData } = navigationProps

    const currentPathname = (new URL(`https://${DOMAIN}${router.asPath}`)).pathname

    const navItems = categoryNavData.map(navData => {
        return <NavItem
            name={navData.name}
            items={navData.expandedItems}
            key={navData.slug}
            setIsSidebarOpen={setIsSidebarOpen}
            currentPathname={currentPathname}
        />
    })

    return (
        <Box>
            <Box width={168} paddingX={2} paddingY={1}>
                <ThemedAppLink href={'/'} pu width={1} aria-label={'Go to homepage'}>
                    <Box
                        component={'img'}
                        src={'/images/reviewhub-logo-blue.svg'}
                        alt='ReviewHub logo'
                        height={1}
                        width={1}
                    />
                </ThemedAppLink>
            </Box>
            <Box paddingX={2} paddingY={2}>
                <Box>
                    {navItems}
                </Box>
                <Box marginTop={1}>
                    <Button
                        size={'large'}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIsSidebarOpen(false)
                            setTimeout(() => {
                                scrollToRef(subscribeRef)
                            }, 100)
                        }}
                        fullWidth
                    >
                        Subscribe
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default SidebarNav
