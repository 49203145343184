import fs from 'fs'
import path from 'path'
import includes from 'lodash/includes'
import { BasicPageData, Callout, FullPageData, ImageMetadata, PageData, Source } from '~/types/pagedata'
import { categoryToSlug, getCategoryMeta, getCategoryMetaKey, getCategoryMetaMapKeys, getSubcategoryMetaKey, getSubcategoryName } from '~/lib/categories'
import { AFFILIATE_CODE_AMAZON, DEFAULT_LOCALE, DEFAULT_ZONE_NAME } from '~/lib/constants'
import { DateTime } from 'luxon'
import { BEST, BUDGET, GREAT, SECOND, UPGRADE } from '~/lib/layout'
import { buildProductLink } from '~/lib/idHelpers'
import { clone } from '~/lib/helpers'

const reviewsDir = path.join(process.cwd(), 'data', 'reviews')
const AMAZON_URL_REGEX = /amazon\.com/
const AMAZON_REGEX = /amazon/i
export const AMAZON = 'Amazon'

const BYPASS_REVIEWED_FLAG = true

let _cachedReviewsData = undefined
const allFlattenedCategories = {}

const calloutLabelMap = {
    [BEST]: 'We recommend',
    [UPGRADE]: 'A better upgrade',
    [BUDGET]: 'Budget friendly',
    [SECOND]: 'Another good choice',
    [GREAT]: 'Another good choice',
}

export function isAmazonUrl(url: string): boolean {
    return AMAZON_URL_REGEX.test(url)
}

export function isAmazonMerchant(merchant: string): boolean {
    return AMAZON_REGEX.test(merchant)
}

/**
 * Helper, can use in frontend
 */
export function findFirstCallout(reviewData: PageData, f?: (Callout) => boolean): Callout|undefined {
    if (!f) {
        // return just the first callout
        return reviewData.layout.find(it => it.type === 'callout')?.callout
    }

    return reviewData.layout.find(node => {
        return node.type === 'callout' ? f(node.callout) : false
    })?.callout
}

/**
 * Helper, can use in frontend
 */
export function findFirstCalloutWithSource(reviewData: PageData, f?: (Source) => boolean): { callout: Callout, source: Source }|undefined {
    if (!f) {
        // return just the first callout source
        const callout = findFirstCallout(reviewData)
        const sources = callout?.sources
        return sources && {
            callout,
            source: sources[0],
        } || undefined
    }

    let matchedSource = undefined
    const callout = reviewData.layout.find(node => {
        const source = node.callout?.sources?.find(it => f(it))
        if (source) {
            matchedSource = source
        }
        return source
    })?.callout

    return matchedSource && {
        callout,
        source: matchedSource,
    } || undefined
}

/**
 * INTERNAL
 */
export function getFirstTargetMerchantLinkFromReviewData(reviewData: FullPageData, merchant: string): string | undefined {
    let link = undefined
    const lowercaseMerchant = merchant.toLowerCase()
    reviewData.layout.find(node => {
        return node.callout?.sources?.find(it => {
            if (it.merchant.toLowerCase() === lowercaseMerchant) {
                link = it.href
                return true
            }
            return false
        }) || false
    })
    return link
}

/**
 * Image extract from sources need to transform to pathname they will be found in
 * @param imagePath
 */
export function transformReviewImagePath(imagePath: string): string {
    return imagePath.replace('./images', '/images/reviews')
}

/**
 * INTERNAL
 */
export function getReviewsData(): FullPageData[] {
    // prevent constant rereading of all these files
    if (_cachedReviewsData) {
        return _cachedReviewsData
    }

    const fileNames = fs.readdirSync(reviewsDir)
    let numIgnored = 0
    const allReviewsData = fileNames.map(fileName => {
        const fullPath = path.join(reviewsDir, fileName)
        const fileContents = fs.readFileSync(fullPath, 'utf8')
        const json = JSON.parse(fileContents)
        const { id, reviewed, category } = json

        // if not manually reviewed, drop it.
        if (!reviewed && !BYPASS_REVIEWED_FLAG) {
            numIgnored++
            return undefined
        }

        category.forEach(cat => {
            // lowercase to normal case
            allFlattenedCategories[cat.toLowerCase()] = cat
        })

        return json
    })

    if (numIgnored > 0) {
        console.log(`Ignored ${numIgnored} reviews not manually reviewed`)
    }
    const res = allReviewsData.filter(it => it !== undefined)
    _cachedReviewsData = res
    console.log('Caching all review data')
    return res
    // Sort posts by date
    // return allReviewsData.sort((a, b) => {
    //     return a.date < b.date ? 1 : -1
    // })
}

/**
 * INTERNAL
 */
export function getFullReviewData(id: string): FullPageData {
    const allData = getReviewsData()
    const data = allData.find(data => data.id === id)
    if (!data) {
        throw new Error(`Review with id=${id} does not exist`)
    }

    // if the object in cache is already transformed, we dont want to touch it again.
    if (data.transformed) {
        return data
    }
    data.transformed = true

    // transform main image link
    if (data.mainImage) {
        data.mainImage.src = transformReviewImagePath(data.mainImage.src)
    }

    data.layout.forEach(node => {
        const callout = node.callout
        if (callout) {
            // transform image link
            const image = callout.image
            image.src = transformReviewImagePath(image.src)

            // transform label
            const label = calloutLabelMap[callout.label]
            if (!label && callout.label) {
                throw new Error(`unknown label=${callout.label} found for callout product=${callout.product} in ${data.id}`)
            }
            callout.label = label

            // remove any duplicate sources and merchants (only 1 unique merchant link)
            const merchants = {}
            callout.sources = callout.sources.filter(it => {
                if (merchants[it.merchant]) {
                    return false
                } else {
                    merchants[it.merchant] = 1
                }
                return true
            })

            // punt amazon source to the front of callout
            const amazonSourceIndex = callout.sources.findIndex(it => isAmazonMerchant(it.merchant))
            if (amazonSourceIndex > 0) {
                const temp = callout.sources[0]
                callout.sources[0] = callout.sources[amazonSourceIndex]
                callout.sources[amazonSourceIndex] = temp
            }
        }
    })

    return data
}

/**
 * Mostly for page props. This has side effect of transforming the source link
 * to our out api affiliate link. Makes a copy of the page data to do that.
 * INTERNAL
 */
export function getReviewData(id: string): PageData {
    const data = clone(getFullReviewData(id))
    const lastUpdated = DateTime.fromMillis(data.ts, { zone: DEFAULT_ZONE_NAME })
        .setLocale(DEFAULT_LOCALE)
        .toLocaleString(DateTime.DATE_MED)

    const categories = data.category.map((it, i) => {
        const isTopLevelCategory = i === 0
        return {
            name: isTopLevelCategory ? getCategoryMeta(it).name : it,
            slug: categoryToSlug(it, isTopLevelCategory),
        }
    })

    // replace source links with generated product links for page props
    data.layout.forEach(node => {
        if (node.callout) {
            node.callout.sources.forEach(it => {
                it.href = buildProductLink(data, node.callout, it)
            })
        }
    })

    return {
        lastUpdated,
        id: data.id,
        title: data.title,
        mainImage: data.mainImage,
        metaDescription: data.metaDescription,
        categories,
        cn: data.cn,
        layout: data.layout,
    }
}

/**
 * INTERNAL
 */
export function getBasicReviewData(id: string): BasicPageData {
    const data = getFullReviewData(id)

    // use main image, but fallback to first one from callout if none found
    const mainImage = getMainImageFromPageDataWithFallback(data)
    const lastUpdated = DateTime.fromMillis(data.ts, { zone: DEFAULT_ZONE_NAME })
        .setLocale(DEFAULT_LOCALE)
        .toLocaleString(DateTime.DATE_MED)

    return {
        id: data.id,
        lastUpdated,
        title: data.title,
        metaDescription: data.metaDescription,
        cn: data.cn,
        mainImage,
    }
}

/**
 * INTERNAL
 */
export function getRandomFullReviewData(): FullPageData {
    const allReviews = getReviewsData()
    return allReviews[Math.floor(Math.random() * allReviews.length)]
}

/**
 * Get Main image from Page Data, or fallback to first callout image
 */
export function getMainImageFromPageDataWithFallback(data: PageData): ImageMetadata {
    return data.mainImage ? data.mainImage : data.layout.find(it => it.type === 'callout').callout.image
}

/**
 * INTERNAL
 * @param categoryBreadcrumbs breadcrumbs of categories, [] to get top level categories
 */
export function getSubcategories(categoryBreadcrumbs: string[] | string): string[] {
    const allData = getReviewsData()
    const cats = []
    categoryBreadcrumbs = Array.isArray(categoryBreadcrumbs) ? categoryBreadcrumbs : [ categoryBreadcrumbs ]

    // length is 0, we fetch top level categories
    // length is 1, we fetch subcategories of given category
    // length is 2, we fetch subcategories of that subcategory
    allData.forEach(it => {
        // check that upper level categories match
        for (let i = 0; i < categoryBreadcrumbs.length; ++i) {
            const catFromData = it.category && it.category[i]
            const catFromBreadcrumb = categoryBreadcrumbs[i]
            if (i === 0) {
                // only top level needs to check additional category aliases for match
                const dataCatMetaKey = getCategoryMetaKey(catFromData)
                const breadcrumbCatMetaKey = getCategoryMetaKey(catFromBreadcrumb)
                if (dataCatMetaKey !== breadcrumbCatMetaKey) {
                    return // not a match, continue with next review
                }
            } else {
                // check subcategory metas
                const dataCatMetaKey = getSubcategoryMetaKey(catFromData)
                const breadcrumbCatMetaKey = getSubcategoryMetaKey(catFromBreadcrumb)

                if (dataCatMetaKey !== undefined && breadcrumbCatMetaKey !== undefined) {
                    if (dataCatMetaKey !== breadcrumbCatMetaKey) {
                        return
                    }
                } else if (catFromBreadcrumb !== catFromData) {
                    return // not a match, continue with next review
                }
            }
        }

        // if reached, assume upper level categories match
        const cat = it.category && it.category[categoryBreadcrumbs.length] && getSubcategoryName(it.category[categoryBreadcrumbs.length])
        if (cat && !includes(cats, cat)) {
            cats.push(cat)
        }
    })
    return cats
}

/**
 * INTERNAL
 * Returns object with keys from CATEGORY_METAS to list of subcategories for that top level category
 * Ex:
 *  {
 *      travel: [ 'Luggage', 'Backpacks' ]
 *  }
 */
export function getReviewCategories(): object {
    const categoryMap = {} // obj with keys of top level category to first subcategories

    getSubcategories([]).forEach(it => {
        const catMetaKey = getCategoryMetaKey(it)
        if (!categoryMap[catMetaKey]) {
            categoryMap[catMetaKey] = getSubcategories(it)
        }
    })

    return categoryMap
}

/**
 * INTERNAL
 * @param categoryBreadcrumbs breadcrumbs of categories to fetch reviews for
 * @param exact whether breadcrumbs match should be exact length
 */
export function getReviewIdsByCategory(categoryBreadcrumbs: string[] | string, exact: boolean = false): string[] {
    const allData = getReviewsData()
    const reviewIds = []
    categoryBreadcrumbs = Array.isArray(categoryBreadcrumbs) ? categoryBreadcrumbs : [ categoryBreadcrumbs ]

    // length is 0, we fetch top level categories
    // length is 1, we fetch subcategories of given category
    // length is 2, we fetch subcategories of that subcategory
    allData.forEach(it => {
        if (exact && it.category.length !== categoryBreadcrumbs.length) {
            return // not a match since not exact
        }

        // check that upper level categories match
        for (let i = 0; i < categoryBreadcrumbs.length; ++i) {
            // for top levels, need to check for category aliases for both input and from review data
            const catFromData = it.category && it.category[i]
            const catFromBreadcrumb = categoryBreadcrumbs[i]

            if (i === 0) {
                // only top level needs to check additional category aliases for match
                const dataCatMetaKey = getCategoryMetaKey(catFromData)
                const breadcrumbCatMetaKey = getCategoryMetaKey(catFromBreadcrumb)
                if (dataCatMetaKey !== breadcrumbCatMetaKey) {
                    return // not a match, continue with next review
                }
            } else {
                // check subcategory metas
                const dataCatMetaKey = getSubcategoryMetaKey(catFromData)
                const breadcrumbCatMetaKey = getSubcategoryMetaKey(catFromBreadcrumb)

                if (dataCatMetaKey !== undefined && breadcrumbCatMetaKey !== undefined) {
                    if (dataCatMetaKey !== breadcrumbCatMetaKey) {
                        return
                    }
                } else if (catFromBreadcrumb !== catFromData) {
                    return // not a match, continue with next review
                }
            }
        }

        // if reached, assume categories match
        const id = it.id
        if (id && !includes(reviewIds, id)) {
            reviewIds.push(id)
        }
    })
    return reviewIds
}

/**
 * All categories across all breadcrumbs flattened into a single Map object for quick lookup
 */
export function getAllFlattenedCategories(): object {
    return allFlattenedCategories
}

/**
 * INTERNAL
 * Validate a category exists
 */
export function validateCategory(category): string | undefined {
    return allFlattenedCategories[category.toLowerCase()]
}

/**
 * INTERNAL
 */
export function getRandomReviewAmazonAffiliateLink(topLevelCatId?: string): string {
    const allMetaKeys = getCategoryMetaMapKeys()
    const relatedCatMetaKey = allMetaKeys.find(metaKey => String(getCategoryMeta(metaKey).id) === topLevelCatId)

    // lets find one specifically that is amazon
    let amazonLink = undefined

    if (relatedCatMetaKey) {
        // get a random review from this category
        const meta = getCategoryMeta(relatedCatMetaKey)
        const reviewIds = getReviewIdsByCategory(meta.name)

        reviewIds.find(reviewId => {
            const data = getFullReviewData(reviewId)
            amazonLink = getFirstTargetMerchantLinkFromReviewData(data, AMAZON)
            return amazonLink
        })
    }

    // get a random review overall
    while (!amazonLink) {
        amazonLink = getFirstTargetMerchantLinkFromReviewData(getRandomFullReviewData(), AMAZON)
    }
    return amazonLink + AFFILIATE_CODE_AMAZON
}