'use strict'

class TextNode {
    constructor(obj) {
        this.type = 'text'
        if (typeof obj === 'object') {
            // if (obj.type !== this.type) throw Error(`unexpected type=${type} passed for TextNode`)
            this.text = obj.text
        } else {
            this.text = obj // it's a text string
        }
    }
}

class LinkNode {
    constructor(obj) {
        this.type = 'link'
        // if (obj.type !== this.type) throw Error(`unexpected type=${type} passed for LinkNode`)
        this.childNode = obj.childNode
        this.href = obj.href
        this.meta = obj.meta
    }
}

class ParagraphNode {
    constructor(children) {
        this.type = 'paragraph'
        this.children = children
    }
}

class CalloutNode {
    constructor(obj) {
        this.type = 'callout'
        this.callout = obj
    }
}

function parseNode(obj) {
    if (!obj.type) throw Error(`No type provided`)
    let res = undefined
    switch (obj.type) {
        case 'text':
            res = new TextNode(obj)
            break
        case 'link':
            const copy = JSON.parse(JSON.stringify(obj))
            copy.childNode = parseNode(copy.childNode)
            res = new LinkNode(copy)
            break
        case 'paragraph':
            const children = obj.children.map(child => parseNode(child))
            res = new ParagraphNode(children)
            break
        case 'callout':
            res = new CalloutNode(obj.callout)
            break
        default:
            throw Error(`unknown object ${JSON.stringify(obj)}`)
    }
    return res
}

module.exports = {
    TextNode,
    ParagraphNode,
    CalloutNode,
    LinkNode,
    parseNode,
    BEST: 'best', // we recommend
    UPGRADE: 'upgrade', // more expensive upgrade
    BUDGET: 'budget', // budget friendly
    SECOND: 'second', // another good choice
    GREAT: 'great', // another good choice
}
