import React, { useEffect, useState } from 'react'
import { alpha, useTheme } from '@mui/material/styles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { NameAndSlug } from '~/types/pagedata'
import { ThemedAppLink } from '~/components/AppLink'

interface SidebarNavItemProps {
    items: NameAndSlug[]
    name: string
    currentPathname: string
    setIsSidebarOpen: (value) => void
}

const NavItem: React.FC<SidebarNavItemProps> = ({ name, items, currentPathname, setIsSidebarOpen }) => {
    const theme = useTheme()
    const [ activeLink, setActiveLink ] = useState('')
    useEffect(() => {
        setActiveLink(currentPathname || '')
    }, [ currentPathname ])

    const hasActiveLink = items.find((i) => i.slug === activeLink)

    return (
        <Box>
            <Accordion
                disableGutters
                elevation={0}
                sx={{ backgroundColor: 'transparent' }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ padding: 0 }}
                >
                    <Typography
                        fontSize={16}
                        fontWeight={hasActiveLink ? 600 : 500}
                        color={hasActiveLink ? 'primary' : 'text.primary'}
                    >
                        {name}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                    <Grid container spacing={1}>
                        {items.map((p, i) => (
                            <Grid item key={`${p.slug}-${i}`} xs={12}>
                                <ThemedAppLink href={p.slug} puCat={`${name}/${p.name}`} onClick={(e) => {
                                    setIsSidebarOpen(false)
                                }}>
                                    <Button
                                        size={'large'}
                                        fullWidth
                                        sx={{
                                            justifyContent: 'flex-start',
                                            color:
                                                activeLink === p.slug
                                                    ? theme.palette.primary.main
                                                    : theme.palette.text.primary,
                                            backgroundColor:
                                                activeLink === p.slug
                                                    ? alpha(theme.palette.primary.main, 0.1)
                                                    : 'transparent',
                                            fontWeight: activeLink === p.slug ? 600 : 500,
                                        }}
                                    >
                                        {p.name}
                                    </Button>
                                </ThemedAppLink>
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default NavItem
