/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import useMediaQuery from '@mui/material/useMediaQuery'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { AppDataContext } from '~/lib/contexts/AppContexts'

const SubscribeCard = () => {
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
        defaultMatches: true,
    })
    const nameRef = React.createRef()
    const emailRef = React.createRef()
    const [ isNameError, setNameError ] = useState(false)
    const [ isEmailError, setEmailError ] = useState(false)
    const [ subscribeResult, setSubscribeResult ] = useState(null)
    const [ subscribeInProgress, setSubscribeInProgress ] = useState(false)

    const { subscribeRef } = useContext(AppDataContext)

    const onNameChanged = name => {
        setNameError(!name)
    }

    const onEmailChanged = email => {
        setEmailError(!email)
    }

    const onSubscribe = async () => {
        const name = nameRef.current.value
        const email = emailRef.current.value
        console.log(`name=${name} email=${email}`)
        setSubscribeInProgress(true)

        try {
            const response = await fetch('/api/subscribe', {
                method: 'post',
                body: JSON.stringify({ name, email })
            })
            if (response.ok) {
                // set subscribe to success
                setNameError(false)
                setEmailError(false)
                setSubscribeResult('success')
            } else {
                const { errorFields } = await response.json()
                if (errorFields.includes('name')) {
                    setNameError(true)
                } else if (errorFields.includes('email')) {
                    setEmailError(true)
                } else {
                    setSubscribeResult('error')
                }
                console.log(errorFields)
            }
            // console.log(response)
        } catch (e) {
            console.error('in catch', e)
            setSubscribeResult('error')
        }
        setSubscribeInProgress(false)
    }

    let buttonText = 'Subscribe'
    let buttonColor = 'primary'
    let buttonIcon = <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        width={24}
        height={24}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        />
    </svg>
    if (subscribeResult === 'success') {
        buttonText = 'Thanks!'
        buttonColor = 'success'
        buttonIcon = <CheckOutlinedIcon/>
    } else if (subscribeResult === 'error') {
        buttonText = 'Error, try again'
        buttonColor = 'error'
        buttonIcon = <ErrorOutlineOutlinedIcon/>
    }

    return (
        <Box component={Card} boxShadow={3} paddingY={3} ref={subscribeRef}>
            <CardContent>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Box marginBottom={4}>
                        <Typography
                            variant="h4"
                            align={'center'}
                            gutterBottom
                        >
                            Subscribe to the newsletter
                        </Typography>
                        <Typography
                            align={'center'}
                            color={'text.secondary'}
                        >
                            Get notified about promotions, exceptional deals, and stay up to date.
                        </Typography>
                    </Box>
                    <Box
                        width={1}
                        display="flex"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        // flexWrap={{ xs: 'nowrap', sm: 'wrap' }}
                        alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                        justifyContent={'center'}
                    >
                        <FormControl
                            fullWidth
                            variant="outlined"
                            sx={{
                                maxWidth: { xs: 1, sm: 300 },
                                width: 1,
                                flexGrow: 2,
                            }}
                        >
                            <InputLabel htmlFor="name">Name</InputLabel>
                            <OutlinedInput id="name" label="Name" inputRef={nameRef} error={isNameError} onChange={(e) => onNameChanged(e.target.value)}/>
                            {isNameError && <FormHelperText id="email-helper">Enter your name.</FormHelperText>}

                        </FormControl>

                        <FormControl
                            fullWidth
                            variant="outlined"
                            sx={{
                                maxWidth: { xs: 1, sm: 300 },
                                width: 1,
                                marginTop: { xs: 2, sm: 0 },
                                marginLeft: { sm: 2 },
                                flexGrow: 2,
                            }}
                        >
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <OutlinedInput id="email" label={'Email'} inputRef={emailRef} error={isEmailError} onChange={(e) => onEmailChanged(e.target.value)}/>
                            {isEmailError && <FormHelperText id="email-helper">Enter a valid email address.</FormHelperText>}
                        </FormControl>
                        <Box sx={{
                            position: 'relative',
                            marginTop: { xs: 2, sm: 0 },
                            marginLeft: { sm: 2 },
                        }}>
                            <Button
                                onClick={onSubscribe}
                                variant="contained"
                                size="large"
                                disabled={subscribeInProgress}
                                fullWidth={!isSm}
                                sx={{
                                    minWidth: { sm: 154 },
                                    height: 56,
                                }}
                                endIcon={buttonIcon}
                            >
                                {buttonText}
                            </Button>
                            {subscribeInProgress && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: 'primary',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Box>
    )
}

export default SubscribeCard