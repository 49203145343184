import { getCategoryMeta, getSubcategoryName } from '~/lib/categories'
import { Callout, FullPageData, Source } from '~/types/pagedata'
import fs from 'fs'
import path from 'path'
import { AFFILIATE_CODE_AMAZON, isAffiliateOutRedirectLinkEnabled, ROUTES } from '~/lib/constants'
import { isAmazonMerchant } from '~/lib/review'

export const CATEGORY_ID_MAP_JSON = path.join(process.cwd(), 'data', 'categoryIdMap.json')
export const MERCHANT_ID_MAP_JSON = path.join(process.cwd(), 'data', 'merchantIdMap.json')
export const PRODUCT_ID_MAP_JSON = path.join(process.cwd(), 'data', 'productIdMap.json')
export const REVIEW_ID_MAP_JSON = path.join(process.cwd(), 'data', 'reviewIdMap.json')
const CATEGORY_SEPARATOR = '~|~'

export interface IdMap {
    [key: string]: number
}
export interface ReversedIdMap {
    [id: number]: string
}

let _cachedCategoryIdMap: IdMap
let _cachedProductIdMap: IdMap
let _cachedReviewIdMap: IdMap
let _cachedMerchantIdMap: IdMap

/**
 * INTERNAL
 */
export function getCategoryIdMap(): IdMap {
    if (!_cachedCategoryIdMap) {
        _cachedCategoryIdMap = JSON.parse(fs.readFileSync(CATEGORY_ID_MAP_JSON, 'utf8'))
    }
    return _cachedCategoryIdMap
}

/**
 * INTERNAL
 */
export function getProductIdMap(): IdMap {
    if (!_cachedProductIdMap) {
        _cachedProductIdMap = JSON.parse(fs.readFileSync(PRODUCT_ID_MAP_JSON, 'utf8'))
    }
    return _cachedProductIdMap
}

/**
 * INTERNAL
 */
export function getReviewIdMap(): IdMap {
    if (!_cachedReviewIdMap) {
        _cachedReviewIdMap = JSON.parse(fs.readFileSync(REVIEW_ID_MAP_JSON, 'utf8'))
    }
    return _cachedReviewIdMap
}

/**
 * INTERNAL
 */
export function getMerchantIdMap(): IdMap {
    if (!_cachedMerchantIdMap) {
        _cachedMerchantIdMap = JSON.parse(fs.readFileSync(MERCHANT_ID_MAP_JSON, 'utf8'))
    }
    return _cachedMerchantIdMap
}

/**
 * Build ID key from category names where toplevel category uses its ID instead of name
 * EX: 9~|~Small Kitchen Appliances
 * INTERNAL
 */
export function buildIdKeyFromCategoryNames(categories: string[]): string {
    const transformedCategories = categories.map((it, i) => {
        const isTopLevel = i === 0
        if (isTopLevel) {
            const meta = getCategoryMeta(it)
            const id = meta?.id
            if (!id || !meta) {
                throw new Error(`Missing toplevel meta or ID, ${JSON.stringify(meta, undefined, 4)}`, )
            }
            return id
        } else {
            return getSubcategoryName(it)
        }
    })

    return transformedCategories.join(CATEGORY_SEPARATOR)
}

/**
 * INTERNAL
 */
export function buildProductLink(pageData: FullPageData, callout: Callout, source: Source): string {
    if (!isAffiliateOutRedirectLinkEnabled) {
        return isAmazonMerchant(source.merchant) ? source.href + AFFILIATE_CODE_AMAZON : source.href
    }

    const { category, id } = pageData
    const topLevelCatId = getCategoryMeta(category[0]).id
    const fullCatId = getCategoryIdMap()[buildIdKeyFromCategoryNames(category)]
    const reviewId = getReviewIdMap()[id.toLowerCase()]
    const productId = getProductIdMap()[callout.product.toLowerCase()]
    const merchantId = getMerchantIdMap()[source.merchant.toLowerCase()]
    return `${ROUTES.outlink}/${topLevelCatId}/${fullCatId}/${reviewId}/${productId}/${merchantId}?merchant=${encodeURIComponent(source.merchant)}`
}

/**
 * INTERNAL
 */
export function reverseIdMap(idMap: IdMap): ReversedIdMap {
    const reversed = {}
    Object.entries(idMap).forEach(pair => {
        const [ key, id ] = pair
        reversed[id] = key
    })
    return reversed
}