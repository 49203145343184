import React from 'react'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import { SidebarNav } from './components'

interface Props {
    setIsSidebarOpen: (value) => void;
    open: boolean;
    variant: string;
}

const Sidebar: React.FC<Props> = ({ open, variant, setIsSidebarOpen }) => {
    return (
        <Drawer
            anchor="left"
            onClose={() => setIsSidebarOpen(false)}
            open={open}
            variant={variant}
            sx={{
                '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: 280,
                },
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    padding: 1,
                }}
            >
                <SidebarNav setIsSidebarOpen={setIsSidebarOpen}/>
            </Box>
        </Drawer>
    )
}

export default Sidebar
