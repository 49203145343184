import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { alpha, useTheme } from '@mui/material/styles'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { NameAndSlug } from '~/types/pagedata'
import { ThemedAppLink } from '~/components/AppLink'

export interface TopbarNavItemProps {
    items: NameAndSlug[]
    name: string
    id: string
    currentPathname: string
}

const NavItem: React.FC<TopbarNavItemProps> = ({ name, id, items, currentPathname }) => {
    const theme = useTheme()

    const [ anchorEl, setAnchorEl ] = useState(null)
    const [ openedPopoverId, setOpenedPopoverId ] = useState(null)

    const handleClick = (event, popoverId) => {
        setAnchorEl(event.target)
        setOpenedPopoverId(popoverId)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpenedPopoverId(null)
    }

    const [ activeLink, setActiveLink ] = useState('')
    useEffect(() => {
        setActiveLink(currentPathname || '')
    }, [ currentPathname ])

    const hasActiveLink = id === activeLink || items.find((i) => i.slug === activeLink)
    const linkColor = 'text.primary'

    return (
        <Box>
            <Box
                display={'flex'}
                alignItems={'center'}
                aria-describedby={id}
                sx={{ cursor: 'pointer' }}
                onClick={(e) => handleClick(e, id)}
            >
                <Typography
                    fontWeight={openedPopoverId === id || hasActiveLink ? 700 : 400}
                    color={linkColor}
                >
                    {name}
                </Typography>
                <ExpandMoreIcon
                    sx={{
                        marginLeft: theme.spacing(1 / 4),
                        width: 16,
                        height: 16,
                        transform: openedPopoverId === id ? 'rotate(180deg)' : 'none',
                        color: linkColor,
                    }}
                />
            </Box>
            <Popover
                elevation={3}
                id={id}
                open={openedPopoverId === id}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    '.MuiPaper-root': {
                        maxWidth: items.length > 12 ? 350 : 250,
                        padding: 2,
                        marginTop: 2,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderTop: `3px solid ${theme.palette.primary.main}`,
                    },
                }}
            >
                <Grid container spacing={0.5}>
                    {items.map((p, i) => (
                        <Grid item key={i} xs={items.length > 12 ? 6 : 12}>
                            <ThemedAppLink href={p.slug} puCat={`${name}/${p.name}`} onClick={(e) => handleClose()}>
                                <Button
                                    fullWidth
                                    sx={{
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                        color:
                                            activeLink === p.slug
                                                ? theme.palette.primary.main
                                                : theme.palette.text.primary,
                                        backgroundColor:
                                            activeLink === p.slug
                                                ? alpha(theme.palette.primary.main, 0.1)
                                                : 'transparent',
                                        fontWeight: activeLink === p.slug ? 600 : 500,
                                    }}
                                >
                                    {p.name}
                                </Button>
                            </ThemedAppLink>
                        </Grid>
                    ))}
                </Grid>
            </Popover>
        </Box>
    )
}

export default NavItem
