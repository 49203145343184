import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import LogoIcon from 'assets/icons/reviewhub-logo.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Divider, Stack } from '@mui/material'
import { ThemedAppLink } from 'components/AppLink'
import { ROUTES } from '../../../../lib/constants'
import Subscribe from './Subscribe'

const Footer = () => {
    const theme = useTheme()
    const { mode } = theme.palette

    const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true, })
    const linkColor = '#000000'

    return (
        <Grid container spacing={4}>
            <Grid xs={12}>
                {/*<LogoIcon width={isMd ? 324 : 122} />*/}
                {/*<Typography*/}
                {/*    mt={7}*/}
                {/*    justifyContent={'left'}*/}
                {/*    fontSize={16}*/}
                {/*    color="text.secondary"*/}
                {/*>*/}
                {/*    ReviewHub is a product recommendation service with an aim to save people time and money*/}
                {/*    when making buying decisions. We'll give advice and help you find the right product*/}
                {/*    based on your budget and quality expectations.*/}
                {/*</Typography>*/}
                <Subscribe/>
            </Grid>
            <Grid xs={12}>
                <Divider color={'#000000'}/>
            </Grid>
            <Grid xs={12} md>
                <Stack
                    sx={{
                        justifyContent: { xs: 'center', md: 'left' }
                    }}
                    direction="row"
                    divider={!isMd ? <Divider orientation="vertical" flexItem /> : false}
                    spacing={2}
                >
                    <ThemedAppLink href={ROUTES.privacy} pu color={linkColor} underline='none'>
                        <Typography variant={'subtitle2'}>Privacy Policy</Typography>
                    </ThemedAppLink>
                    <ThemedAppLink href={ROUTES.termsOfService} pu color={linkColor} underline='none'>
                        <Typography variant={'subtitle2'}>Terms of Use</Typography>
                    </ThemedAppLink>
                    {/*<ThemedAppLink href='/'>Cookie Policy</ThemedAppLink>*/}
                </Stack>
            </Grid>
            <Grid md={'auto'} xs={12}>
                <Typography
                    align={'center'}
                    variant={'subtitle2'}
                    color="text.secondary"
                >
                    &copy; 2023 ReviewHub. All rights reserved
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Footer
