import { validateCategory } from '~/lib/review'
import { CategoryDescriptionData } from '~/types/pagedata'
import fs from 'fs'
import { Index } from '~/types/general'
import path from 'path'

const CATEGORY_DESCRIPTION_JSON_FILE = path.join(process.cwd(), 'data', 'categoryDescriptions.json')
const CATEGORY_METAS_JSON_FILE = path.join(process.cwd(), 'data', 'categoryMetas.json')
const SUBCATEGORY_METAS_JSON_FILE = path.join(process.cwd(), 'data', 'subcategoryMetas.json')

export type CategoryMetaKey = string
export interface ICategoryMeta {
    name: string
    shortName?: string
    slug: string
}

/**
 * Internal
 */
export interface CategoryMeta extends ICategoryMeta {
    aliases: string[]
    id: number
}
export interface CategoryMetaPageProps extends ICategoryMeta {
}
export function categoryMetaToPageProps(meta: CategoryMeta): CategoryMetaPageProps {
    return {
        name: meta.name,
        shortName: meta.shortName,
        slug: meta.slug,
    }
}

interface CategoryMetaMap {
    [key: CategoryMetaKey]: CategoryMeta
}
interface CategoryMetaAliasMap {
    [alias: string]: CategoryMetaKey
}
type SubcategoryMetaMap = CategoryMeta[]
interface SubcategoryMetaAliasMap {
    [alias: string]: Index
}

// TODO if subcategory metas requires a specific parent category, perhaps optional requiresParentCategory: 'gifts'

let _cachedCategoryMetaMap: CategoryMetaMap | undefined = undefined
let _cachedCategoryMetaAliasMap: CategoryMetaAliasMap | undefined = undefined
let _cachedSubcategoryMetaMap: SubcategoryMetaMap | undefined = undefined
let _cachedSubcategoryMetaAliasMap: SubcategoryMetaAliasMap | undefined = undefined

/**
 * Usually for lookup usually by slug to name and only for top level categories.
 * One to one relationship
 * INTERNAL
 */
function getCategoryMetaMap(): CategoryMetaMap {
    if (!_cachedCategoryMetaMap) {
        _cachedCategoryMetaMap = JSON.parse(fs.readFileSync(CATEGORY_METAS_JSON_FILE, 'utf8'))
    }
    return _cachedCategoryMetaMap
}

/**
 * INTERNAL
 */
function getSubcategoryMetaMap(): SubcategoryMetaMap {
    if (!_cachedSubcategoryMetaMap) {
        _cachedSubcategoryMetaMap = JSON.parse(fs.readFileSync(SUBCATEGORY_METAS_JSON_FILE, 'utf8'))
    }
    return _cachedSubcategoryMetaMap
}

/**
 * INTERNAL
 */
export function getCategoryMetaMapKeys(): string[] {
    return Object.keys(getCategoryMetaMap())
}

/**
 * Reverse of category metas for quick lookup from category names from source to meta.
 * Usually for lookup from name to slug/consolidated name and only for top level categories.
 * Many to one relationship.
 * INTERNAL
 */
function getCategoryMetaAliasMap(): CategoryMetaAliasMap {
    if (_cachedCategoryMetaAliasMap) {
        return _cachedCategoryMetaAliasMap
    }

    const metas = getCategoryMetaMap()
    const metaKeys = getCategoryMetaMapKeys()
    const aliasMap: CategoryMetaAliasMap = {}
    for (const metaKey of metaKeys) {
        const meta = metas[metaKey]
        meta.aliases.forEach(alias => {
            aliasMap[alias.toLowerCase()] = metaKey
        })
        aliasMap[metaKey.toLowerCase()] = metaKey
        aliasMap[meta.name.toLowerCase()] = metaKey
        if (meta.shortName) {
            aliasMap[meta.shortName.toLowerCase()] = metaKey
        }
    }
    _cachedCategoryMetaAliasMap = aliasMap
    return _cachedCategoryMetaAliasMap
}

/**
 * Subcategory meta is array and alias will hold an index position
 * INTERNAL
 */
function getSubcategoryMetaAliasMap(): SubcategoryMetaAliasMap {
    if (_cachedSubcategoryMetaAliasMap) {
        return _cachedSubcategoryMetaAliasMap
    }

    const metas = getSubcategoryMetaMap()
    const aliasMap: SubcategoryMetaAliasMap = {}
    metas.forEach((meta, i) => {
        meta.aliases.forEach(alias => {
            aliasMap[alias.toLowerCase()] = i
        })
        aliasMap[meta.name.toLowerCase()] = i
        if (meta.shortName) {
            aliasMap[meta.shortName.toLowerCase()] = i
        }
    })
    _cachedSubcategoryMetaAliasMap = aliasMap
    return _cachedSubcategoryMetaAliasMap
}

/**
 * INTERNAL
 */
export function getSubcategoryName(category: string): string {
    return getSubcategoryMeta(category)?.name || category
}

/**
 * INTERNAL
 */
export function getCategoryMeta(category: string): CategoryMeta {
    const metaKey = getCategoryMetaKey(category)
    const meta = getCategoryMetaMap()[metaKey]
    if (!meta) {
        throw new Error(`Unknown category '${category}'`)
    }
    return meta
}

/**
 * INTERNAL
 */
export function getSubcategoryMeta(category: string): CategoryMeta | undefined {
    const key = getSubcategoryMetaKey(category)
    return getSubcategoryMetaMap()[key]
}

/**
 * INTERNAL
 */
export function getCategoryMetaFromSlug(slug: string): CategoryMeta | undefined {
    if (!slug || slug.length === 0) {
        console.warn(`Invalid category Url '${slug}'`)
        return undefined
    }

    const categoryMetaKeys = getCategoryMetaMapKeys()
    const targetCategorySlug = slug[0] === '/' ? slug : `/${slug}`
    for (const categoryKey of categoryMetaKeys) {
        const meta = getCategoryMetaMap()[categoryKey]
        if (meta.slug === targetCategorySlug) {
            return meta
        }
    }

    console.warn(`Unknown category slug '${targetCategorySlug}'`)
    return undefined
}

/**
 * INTERNAL
 */
export function getSubcategoryMetaFromSlug(slug: string): CategoryMeta | undefined {
    if (!slug || slug.length === 0) {
        return undefined
    }

    const normalizedSlug = slug[0] === '/' ? slug.substring(1) : slug // strip slash here
    return getSubcategoryMetaMap().find(it => it.slug === normalizedSlug)
}

/**
 * INTERNAL
 */
export function getCategoryMetaKey(category: string): CategoryMetaKey {
    const lowercase = category.toLowerCase()
    const metaKey = getCategoryMetaAliasMap()[lowercase]
    if (!metaKey) {
        throw new Error(`Unknown category '${lowercase}'`)
    }
    return metaKey
}

/**
 * INTERNAL
 */
export function getSubcategoryMetaKey(category: string): Index | undefined {
    const lowercase = category?.toLowerCase()
    return getSubcategoryMetaAliasMap()[lowercase]
}

/**
 * Common rules to transform a category name to a url string.
 * Usually for subcategories. Top level categories are mapped above.
 *
 * INTERNAL
 */
export function categoryToSlug(categoryName: string, isTopLevelCategory: boolean = false): string {
    if (isTopLevelCategory) {
        return getCategoryMeta(categoryName).slug.slice(1)
    } else {
        const subcatMeta = getSubcategoryMeta(categoryName)
        if (subcatMeta && subcatMeta.slug) {
            return subcatMeta.slug
        }
    }

    return categoryName.toLowerCase()
        .replaceAll(' & ', '-')
        .replaceAll(' ', '-')
}

/**
 * Common rules to transform a category url string to its category name.
 * Usually for subcategories. Top level categories are mapped above.
 *
 * INTERNAL
 * DO NOT USE OUTSIDE getStaticProps.
 * The dependencies of this are heavy
 */
export function slugToCategory(slug: string, isTopLevelCategory: boolean = false): string | undefined {
    const lowercase = slug.toLowerCase()

    // 1. handle top level category specials
    if (isTopLevelCategory) {
        const meta = getCategoryMetaFromSlug(lowercase)
        return meta?.name
    } else {
        const subcatMeta = getSubcategoryMetaFromSlug(slug)
        if (subcatMeta && subcatMeta.name) {
            return subcatMeta.name
        }
    }

    // 2. replace all dashes with ' & '
    let category = lowercase.replaceAll('-', ' & ')
    let validCategory = validateCategory(category)
    if (validCategory) return validCategory

    // 3. replace all dashes with spaces
    category = lowercase.replaceAll('-', ' ')
    validCategory = validateCategory(category)
    if (validCategory) return validCategory

    // 4. replace first dash with & and second with space
    category = lowercase.replace('-', ' & ')
        .replace('-', ' ')
    validCategory = validateCategory(category)
    if (validCategory) return validCategory

    // 5. replace first dash with space and second with &
    category = lowercase.replace('-', ' ')
        .replace('-', ' & ')
    validCategory = validateCategory(category)
    if (validCategory) return validCategory

    return undefined
}

/**
 * INTERNAL
 */
export function getCategoryDescriptions(): CategoryDescriptionData[] {
    return JSON.parse(fs.readFileSync(CATEGORY_DESCRIPTION_JSON_FILE, 'utf8'))
}