import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import Container from 'components/Container'
import TopNav from 'components/TopNav'
import Topbar from 'layouts/Main/components/Topbar'
import Sidebar from 'layouts/Main/components/Sidebar'

import { Footer } from './components'
import { Paper } from '@mui/material'

interface Props {
    children: any;
    colorInvert?: boolean;
    bgcolor?: string;
}

const Main: React.FC<Props> = ({ children, colorInvert = false, bgcolor = 'transparent' }) => {
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    })

    const [ isSidebarOpen, _setIsSidebarOpen ] = useState(false)

    const setIsSidebarOpen = (value) => {
        _setIsSidebarOpen(value)
    }

    const open = isMd ? false : isSidebarOpen

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 38,
    })

    return (
        <Box>
            {/*<Box bgcolor={bgcolor} position={'relative'} zIndex={theme.zIndex.appBar}>*/}
            {/*  <Container paddingTop={'8px !important'} paddingBottom={'0 !important'}>*/}
            {/*    <TopNav colorInvert={colorInvert} />*/}
            {/*  </Container>*/}
            {/*</Box>*/}
            <AppBar
                position={'sticky'}
                sx={{
                    top: 0,
                    backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
                }}
                elevation={trigger ? 1 : 0}
            >
                <Container paddingY={1}>
                    <Topbar
                        setIsSidebarOpen={setIsSidebarOpen}
                        colorInvert={trigger ? false : colorInvert}
                    />
                </Container>
            </AppBar>
            <Sidebar
                setIsSidebarOpen={setIsSidebarOpen}
                open={open}
                variant="temporary"
            />
            <main>
                {children}
                {/*<Divider />*/}
            </main>
            <Paper>
                <Container paddingY={4}>
                    <Footer/>
                </Container>
            </Paper>
        </Box>
    )
}

export default Main
